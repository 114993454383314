<template>
  <v-content sticky-container>
    <v-container v-if="_.size(order) > 0" grid-list-lg fluid>
      <v-layout wrap align-top justify-left>
        <v-flex xs4 class="text-xs-center d-print-none">
          <v-btn color="error" block @click="printPOS"> <v-icon left>dock</v-icon> Imprimir POS</v-btn>
        </v-flex>
        <v-flex xs4 class="text-xs-center d-print-none">
          <v-btn
            target="_blank"
            :href="`https://www.florista.cl/print_message.php?code=${order.cod_unico}`"
            color="success"
            block
          >
            <v-icon left>mail</v-icon> Mensaje</v-btn
          >
        </v-flex>
        <v-flex xs4 class="text-xs-center d-print-none">
          <v-btn color="info" block @click="print"> <v-icon left>print</v-icon> Pedido</v-btn>
        </v-flex>
        <v-flex xs12 class="text-xs-center">
          <h1 class="headline mb-2">
            PEDIDO <span class="font-weight-bold">Nº {{ order.id }}, </span>
            <span>{{ order.shipping.date | moment('dddd DD MMMM YYYY') }}</span>
            [<span v-html="order.shipping.schedule"></span>]
          </h1>
        </v-flex>
        <v-flex xs6 class="body-1">
          <p class="body-2 mb-0" v-html="order.shipping.name"></p>
          <p class="mb-0" v-html="`${order.shipping.address} ${order.shipping.address_number}`"></p>
          <p class="mb-0" v-html="`${order.shipping.more}`"></p>
          <p class="mb-0" v-html="`${order.shipping.commune}`"></p>
          <p class="mb-0" v-html="`${order.shipping.phone}`"></p>
          <v-alert v-if="order.shipping.photo_hidden" :value="true" type="error">
            Foto de entrega NO debe ser con la persona recibiendo
          </v-alert>
          <v-alert v-if="maxQuantity" :value="true" type="warning"> Este pedido lleva mas de 1 PRODUCTO </v-alert>
          <v-alert v-if="order.priority.name === 'alta'" :value="true" type="error">
            Debe ser entregado antes de las {{ order.priority.hour }} hrs
          </v-alert>
        </v-flex>
        <v-flex xs6>
          <v-data-table :items="order.products" class="elevation-1" hide-actions hide-headers>
            <template slot="items" slot-scope="props">
              <td class="title font-weight-bold">{{ props.item.quantity }}</td>
              <td class="text-xs-right">
                <img :src="props.item.photo_small" width="80" />
              </td>
              <td class="text-xs-center">
                <p class="mb-2 body-2">{{ props.item.name }} (P{{ props.item.id }})</p>
                <template v-for="(atributo, index) in props.item.atributos">
                  <p :key="index" class="mb-0 caption">{{ atributo.name }}</p>
                </template>
              </td>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex xs6>
          <gmap-map
            id="map"
            ref="myMap"
            :center="center"
            :zoom="17"
            :options="options"
            :style="`width:100%;height:500px;`"
          >
            <GmapMarker :position="latLngOrder" />
          </gmap-map>
        </v-flex>
        <v-flex xs6>
          <gmap-map
            id="map"
            ref="myMap1"
            :center="center"
            :zoom="12"
            :options="options"
            :style="`width:100%;height:244px;`"
            class="mb-2"
          >
            <GmapMarker :position="latLngOrder" />
          </gmap-map>
          <gmap-map
            id="map"
            ref="myMap2"
            :center="center"
            :zoom="15"
            :options="options"
            :style="`width:100%;height:244px;`"
          >
            <GmapMarker :position="latLngOrder" />
          </gmap-map>
        </v-flex>
        <v-flex xs12>
          <v-alert v-if="order.shipping.type === 'ANONIMO'" :value="true" type="error"> Envío anonimo </v-alert>
          <v-alert v-else :value="true" type="info">
            {{ order.user.name }} | {{ order.user.email }} |
            <span class="font-weight-bold">+{{ order.user.phone }}</span>
          </v-alert>
        </v-flex>
        <v-flex xs12 class="caption" v-html="order.message"></v-flex>
        <v-flex xs12 class="pt-0">
          <v-divider class="mt-0 pt-0"></v-divider>
        </v-flex>
        <v-flex xs12 class="text-xs-center">
          <p class="mb-0 headline font-weight-bold" v-html="order.shipping.name"></p>
          <p
            class="mb-0 title font-weight-regular"
            v-html="
              `${order.shipping.address} ${order.shipping.address_number} ${order.shipping.more} ${
                order.shipping.commune
              }`
            "
          ></p>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import qz from 'qz-tray'
import { ORDER } from '../../config'

export default {
  name: 'OrderPrint',
  props: ['id'],
  data() {
    return {
      printers: null,
      allDoc: [],
      center: { lat: -33.4262, lng: -70.6429 },
      zoom: 10,
      options: {
        styles: [],
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      },
      latLngOrder: {},
      order: {},
      schedule: {},
      windowHeight: window.innerHeight - 420
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    height() {
      return `${window.innerHeight - 80}px`
    },
    maxQuantity() {
      let quantity = false
      this.order.products.forEach(element => {
        if (element.quantity > 1) {
          quantity = true
        }
      })
      return quantity
    }
  },
  watch: {
    id() {
      this.getOrder()
    }
  },
  mounted() {
    this.getOrder()
    qz.websocket.connect().then(() => {
      qz.printers.find().then(printers => {
        this.printers = printers
      })
    })
  },
  methods: {
    printPOS() {
      this.formatPrint(this.order)
    },
    formatPrint(o) {
      const info = o.shipping.more !== '' ? `${o.shipping.more}` : ''
      let products = ''
      let destacado = ''
      let noPhoto = ''
      let express = ''
      if (o.priority.name === 'alta') {
        express = `Debe ser entregado antes de las ${o.priority.hour} hrs`
      }
      const date = `${this.$moment(o.shipping.date).format('dddd')} ${this.$moment(o.shipping.date).format(
        'DD'
      )} de ${this.$moment(o.shipping.date).format('MMMM')}, ${this.$moment(o.shipping.date).format('YYYY')}`
      let numProduct = 0
      o.products.forEach(e => {
        numProduct++
        let atributos = '\x0A'
        e.atributos.forEach(a => {
          atributos += `[${a.name}]\x0A`
        })
        products += `${e.quantity} x ${e.name} (P${e.id}) ${atributos}`
        if (e.comment) {
          products += `${e.comment}\x0A`
        }
        if (numProduct !== o.products.length) {
          products += '---\x0A'
        }
      })
      o.importants.forEach(e => {
        destacado += `${e.message.trim()}\x0A`
      })
      destacado += destacado !== '' ? '\x0A' + '------------------------------------------------' + '\x0A' + '\x0A' : ''
      noPhoto = o.shipping.photo_hidden ? 'Foto de entrega NO debe ser con la persona recibiendo' + '\x0A' : ''
      let data = [
        '\x1B' + '\x40', // init
        '\x1B' + '\x61' + '\x30', // right align
        '\x1B' + '\x21' + '\x30', // em mode on
        `Pedido ${this.$options.filters.toPriceOut(o.id)}`,
        '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
        '\x0A',
        '\x1B' + '\x21' + '\x30', // em mode on
        `${o.shipping.name}` + '\x0A',
        '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
        '\x1b\x21\x20', // double width
        '\x1B' + '\x45' + '\x0D', // bold on
        `${o.shipping.address} ${o.shipping.address_number}` + '\x0A',
        `${info}` + '\x0A',
        `${o.shipping.commune}` + '\x0A',
        `${o.shipping.phone ? o.shipping.phone + '\x0A' : ''}`,
        '\x1B' + '\x45' + '\x0A', // bold off
        '\x1b\x21\x00', // double width off
        '------------------------------------------------' + '\x0A' + '\x0A',
        noPhoto ? '\x1B' + '\x45' + '\x0D' : '',
        `${noPhoto}`,
        noPhoto ? '\x1B' + '\x45' + '\x0A' : '',
        express ? '\x1B' + '\x45' + '\x0D' : '',
        `${express}`,
        express ? '\x1B' + '\x45' + '\x0A' : '',
        '\x1B' + '\x4D' + '\x30', // normal text
        `${date}` + '\x0A',
        '\x1B' + '\x45' + '\x0D', // bold on
        `${o.shipping.schedule}`,
        '\x1B' + '\x45' + '\x0A', // bold off
        '\x0A' + '\x0A',
        '\x1B' + '\x4D' + '\x30', // normal text
        '------------------------------------------------' + '\x0A' + '\x0A',
        `${products}`,
        '\x0A' + '------------------------------------------------' + '\x0A' + '\x0A',
        o.shipping.type === 'NORMAL' ? `${o.user.name}` + '\x0A' : '',
        o.shipping.type === 'NORMAL' ? `+${o.user.phone}` + '\x0A' + '\x0A' : 'ENVIO ANONIMO' + '\x0A' + '\x0A',
        '------------------------------------------------' + '\x0A',
        `${o.message.replace(/<[^>]*>?/gm, '')}` + '\x0A' + '\x0A',
        '------------------------------------------------' + '\x0A' + '\x0A',
        `${destacado}`,
        '\x0A' + '\x0A' + '\x0A', // saltos de linea antes de cut
        '\x1D' + '\x56' + '\x00', // full cut (new syntax)
        '\x10' + '\x14' + '\x01' + '\x00' + '\x05'
      ]
      data = data.map(e => {
        return e.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      })
      this.addPrinter(data)
    },
    addPrinter(d) {
      const printer = this.settings.printer
      d.forEach(e => {
        this.allDoc.push(e)
      })
      const config = qz.configs.create(printer, { encoding: 'ISO-8859-1' })
      qz.print(config, this.allDoc)
      this.allDoc = []
    },
    print() {
      window.print()
    },
    updateform() {
      this.schedule.date = this.order.shipping.date
      this.schedule.name = this.order.shipping.schedule
      this.latLngOrder = {
        lat: this.order.shipping.latitude,
        lng: this.order.shipping.longitude
      }
      this.center = this.latLngOrder
    },
    async getOrder() {
      const res = await this.$http.get(`${ORDER}/${this.id}`)
      if (res.data) {
        this.order = res.data
        this.$store.dispatch('updateTitle', `Pedido ${this.order.id}`)
        this.updateform()
      }
    }
  }
}
</script>
